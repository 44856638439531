import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { request } from "../api/config"

const initialState = {
    isLoading: false,
    success: false,
    message: null,
    gyms: [],
    isSubmit: false,
    successSubmit: false,
}

export const getGyms = createAsyncThunk(
    'gyms/',
    async (values, thunkAPI) => {
        try {
            const res = await request('/locals')
            if (res.code === 200) {
                return res.value
            } else {
                return thunkAPI.rejectWithValue('Houve um erro ao retornar as academias.')
            }
        } catch (e) {
            return thunkAPI.rejectWithValue('Houve um erro ao retornar as academias.')
        }
    }
)

export const deleteGym = createAsyncThunk(
    'gyms/delete',
    async (values, thunkAPI) => {
        try {
            const res = await request(`/locals/${values.id}`, "DELETE")
            if (res.code === 200) {
                return true
            } else {
                return thunkAPI.rejectWithValue('Houve um erro ao deletar a academia.')
            }
        } catch (e) {
            return thunkAPI.rejectWithValue('Houve um erro ao deletar a academia.')
        }
    }
)

export const createGym = createAsyncThunk(
    'gyms/create',
    async (values, thunkAPI) => {
        try {
            const data = {
                ...values,
                complement: values.complement !== undefined ? values.complement : '',
                localType: "GYM"
            }

            const res = await request(data?.id ? `/locals/${data.id}` : '/locals', data?.id ? "PUT" : "POST", { ...data, localType: "GYM" })
            if (res.code === 200) {
                return true
            } else {
                return thunkAPI.rejectWithValue('Houve um erro ao criar a academia.')
            }
        } catch (e) {
            return thunkAPI.rejectWithValue('Houve um erro ao criar a academia.')
        }
    }
)

export const updateGym = createAsyncThunk(
    'gyms/update',
    async (values, thunkAPI) => {
        try {
            const data = {
                ...values,
                complement: values.complement !== undefined ? values.complement : '',
                localType: "GYM"
            }
            const res = await request(`/locals/${values.id}`, "PUT", data)
            if (res.code === 200) {
                return true
            } else {
                return thunkAPI.rejectWithValue('Houve um erro ao atualizar a academia.')
            }
        } catch (e) {
            return thunkAPI.rejectWithValue('Houve um erro ao atualizar a academia.')
        }
    }
)

const gymsSlice = createSlice({
    name: 'gyms',
    initialState,
    extraReducers(builder) {
        builder
            .addCase(getGyms.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getGyms.fulfilled, (state, { payload }) => {
                state.isLoading = false
                state.success = true
                state.gyms = payload
            })
            .addCase(getGyms.rejected, (state, { payload }) => {
                state.isLoading = false
                state.success = false
                state.message = payload
            })
            .addCase(deleteGym.pending, (state) => {
                state.isSubmit = true
            })
            .addCase(deleteGym.fulfilled, (state) => {
                state.isSubmit = false
                state.successSubmit = true
            })
            .addCase(deleteGym.rejected, (state, { payload }) => {
                state.isSubmit = false
                state.successSubmit = false
                state.message = payload
            })
            .addCase(createGym.pending, (state) => {
                state.isSubmit = true
            })
            .addCase(createGym.fulfilled, (state) => {
                state.isSubmit = false
                state.successSubmit = true
            })
            .addCase(createGym.rejected, (state, { payload }) => {
                state.isSubmit = false
                state.successSubmit = false
                state.message = payload
            })
    }
})

const { reducer } = gymsSlice

export default reducer

