import { Col, Row, List, Checkbox, Card } from "antd"

const Step3 = ({ gyms, selectedGyms, gymError, handleSelectGym }) => {

    const title = selectedGyms.length > 0 ? selectedGyms.length > 1 ? `${selectedGyms.length} academias seleciondas` : `${selectedGyms.length} academia selecionada` : 'Nenhuma academia selecionada'

    return (
        <Col md={24}>
            <div className="form-wrapper">
                <Row gutter={[50, 15]}>
                    <Col md={8}>
                        <h3 className="tx-bold">Academias do profissional</h3>
                        <List
                            dataSource={gyms}
                            renderItem={(item, index) => (
                                <List.Item id={`gym-${index}`} onClick={() => handleSelectGym(item)}>
                                    <div>
                                        <h4 className="tx-bold">{item.name}</h4>
                                        <h5>{item.street}</h5>
                                    </div>
                                </List.Item>
                            )}
                        />
                    </Col>
                    <Col md={16} style={{borderLeft: '1px solid #efefef'}}>
                        <h3>{title}</h3>
                        {gymError && <span className="tx-red">Por favor, selecione uma academia.</span>}
                        <Row gutter={[15,15]} className="mt-20">
                            {selectedGyms.map((gym, i) => (
                                <Col md={12} key={`selected-gym-${i}`}>
                                    <Card>
                                        <Checkbox onChange={() => handleSelectGym(gym)} checked>{gym.name}</Checkbox>
                                    </Card>
                                </Col>
                            ))}
                        </Row>               
                    </Col>
                </Row>
            </div>
        </Col>
    )
}

export default Step3