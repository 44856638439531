import { Card } from "antd"
import { EditOutlined } from '@ant-design/icons';
const { Meta } = Card

const CardSpecialty = ({ specialty, handleEdit }) => {
    return (
        <Card
            hoverable
            cover={<img src={specialty.picture} alt="Imagem da especialidade" />}
            actions={[
                <EditOutlined key="edit" onClick={() => handleEdit(specialty)} />
            ]}
        >
            <Meta title={specialty.name} description={`R$${specialty.price}`} />
        </Card>
    )
}

export default CardSpecialty