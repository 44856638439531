import { LayoutComponent as Layout } from "../../components"
import { Row, Col, Button } from 'antd'
import TableProfessionals from "./tableProfessionals"
import { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { getPersonals } from '../../redux/slices/personalsSlice'
import { Link } from "react-router-dom"

const Professionals = () => {

    const { isLoading, personals } = useSelector(state => state.personals)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getPersonals())
    }, [])

    return (
        <Layout>
            <Row gutter={[20,20]}>
                <Col md={12}>
                    <h2 className="title">Profissionais cadastrados</h2>
                </Col>
                <Col md={12} className="justify-end">
                    <Link to="/profissionais/novo"><Button type="primary" size="large">Cadastrar profissional</Button></Link>
                </Col>
                <Col md={24} className="mt-50">
                    <TableProfessionals isLoading={isLoading} data={personals} />
                </Col>
            </Row>
        </Layout>
    )
}

export default Professionals