import { TableComponent as Table } from "../../components"
import Link from "antd/lib/typography/Link"

const TableProfessionals = ({ isLoading, data }) => {
    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            align: 'center'
        }, {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            align: 'center'
        }, {
            title: 'Nome',
            dataIndex: 'firstName',
            key: 'name',
            align: 'center',
            render: (value, row) => {
                return  (<Link href={`/profissionais/${row.id}`}>{value} {row.lastName}</Link>)
            }
        }, {
            title: 'Data de cadastro',
            dataIndex: 'creationDate',
            key: 'creationDate',
            align: 'center',
            render: (value) => {
                const date = new Date(value)
                let month = date.getMonth() + 1
                month = month <= 9 ? '0'+month : month
                return date.getDate()+'/'+month+'/'+date.getFullYear()
            }
        }
    ]


    return (
        <Table columns={columns} data={data} isLoading={isLoading} />
    )
}

export default TableProfessionals