import { LayoutComponent as Layout } from '../../components'
import { Row, Col, Modal, message as alert, List, Form } from 'antd'
import CardSpecialty from './cardSpecialty'
import { PlusCircleOutlined } from '@ant-design/icons'
import { useState } from 'react'
import FormSpecialty from './formSpecialty'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getSpecialties, createSpecialty, updateSpecialty } from '../../redux/slices/specialtiesSlice'

const Specialties = () => {
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [editMode, setEditMode] = useState(false)
    const [selectedId, setSelectedId] = useState(-1)
    const [form] = Form.useForm()
    const { isLoading, specialties, isSubmit } = useSelector(state => state.specialties)
    const dispatch = useDispatch()

    const items = [{}, ...specialties]

    const handleCreate = async (values) => {
        dispatch(createSpecialty(values)).then((res) => {
            if(res.error) {
              return alert.error(res.payload)
            } 
            alert.success('Especialidade criada com sucesso!')
            handleCloseForm()
            dispatch(getSpecialties())
        }).catch(err => {
            alert.error('Ops, houve um erro ao tentar criar a especialidade.')
        })
    }

    const handleUpdate = (values) => {
        values.id = selectedId
        dispatch(updateSpecialty(values)).then((res) => {
            if(res.error) {
              return alert.error(res.payload)
            } 
            alert.success('Especialidade atualizada com sucesso!')
            handleCloseForm()
            setEditMode(false)
            dispatch(getSpecialties())
        }).catch(err => {
            alert.error('Ops, houve um erro ao tentar atualizar a especialidade.')
        })
    }

    const handleEdit = (specialty) => {
        form.setFieldsValue(specialty)
        setSelectedId(specialty.id)
        setEditMode(true)
        setIsModalOpen(true)
    }

    const handleCloseForm = () => {
        setIsModalOpen(false)
        setEditMode(false)
        form.resetFields()
        setSelectedId(-1)
    }

    useEffect(() => {
        dispatch(getSpecialties())
    }, [])

    return (
        <Layout>
            <Row gutter={[20,20]}>
                <Col md={24}>
                    <h2 className='title'>Especialidades Cadastradas</h2>
                </Col>
            </Row>
            <Row gutter={[20,20]} className="mt-50">
                <Col md={24}>
                    <List 
                        grid={{ gutter: 15, column: 4 }}
                        dataSource={items}
                        loading={isLoading}
                        renderItem={(specialty, index) => index === 0 ? (
                            <div className='flex-center h-full'>
                                <div className="btn-add" onClick={() => setIsModalOpen(!isModalOpen)}>
                                    <PlusCircleOutlined />
                                    <h3 className="tx-gray">Adicionar especialidade</h3>
                                </div>
                            </div>
                        ) : (
                            <List.Item>
                                <CardSpecialty specialty={specialty} handleEdit={handleEdit} />
                            </List.Item>
                            )
                        }
                    />
                </Col>
                
            </Row>

            <Modal
                open={isModalOpen}
                onCancel={handleCloseForm}
                title="Nova especialidade"
                maskClosable={false}
                footer={''}
            >
                <FormSpecialty 
                    onSubmit={handleCreate} 
                    closeModal={handleCloseForm} 
                    isSubmit={isSubmit}
                    form={form}
                    editMode={editMode}
                    onUpdate={handleUpdate}
                />
            </Modal>
        </Layout>
    )
}

export default Specialties