import { LayoutComponent as Layout } from "../../components"
import { Row, Col, Form, Button, message as alert } from 'antd'
import { useState, useEffect } from "react"
import { useDispatch, useSelector } from 'react-redux'
import { getSpecialties } from '../../redux/slices/specialtiesSlice'
import { getLanguages } from "../../redux/slices/languagesSlice"
import { getGyms } from "../../redux/slices/gymsSlice"
import { createPersonal, createPersonalAuth0, postAuthRole } from "../../redux/slices/personalsSlice"
import Step1 from "./step1"
import Step2 from "./step2"
import Step3 from "./step3"
import { useNavigate } from "react-router-dom"

const CreateProfessional = () => {

    const [step, setStep] = useState(1)
    const dispatch = useDispatch()
    const { specialties } = useSelector(state => state.specialties)
    const { languages } = useSelector(state => state.languages)
    const { gyms } = useSelector(state => state.gyms)
    const { isSubmit } = useSelector(state => state.personals)
    const [form] = Form.useForm()
    const navigate = useNavigate()

    const [selectedLanguages, setSelectedLanguages] = useState([])
    const [langError, setLangError] = useState(false)

    const [selectedSpecialties, setSelectedSpecialties] = useState([])
    const [specialtyError, setSpecialtyError] = useState(false)

    const [selectedGyms, setSelectedGyms] = useState([])
    const [gymError, setGymError] = useState(false)

    const formatDate = (inputDate) => {
        const parts = inputDate.split('/');
        if (parts.length === 3) {
            const [day, month, year] = parts;
            // Use JavaScript's Date object to create a new date in the desired format
            const formattedDate = new Date(`${year}-${month}-${day}`);
            return formattedDate.toISOString().split('T')[0];
        }
        return 'Invalid Date';
    }

    const handleCreatePersonal = () => {
        const formattedDate = formatDate(form.getFieldValue('birthDate'))
        const data = {
            ...form.getFieldsValue(['firstName', 'lastName', 'cref', 'cpf', 'gender', 'email', 'phoneNumber', 'personalZoopId', 'photo']),
            birthDate: formattedDate,
            locals: selectedGyms,
            languages: selectedLanguages,
            specialties: selectedSpecialties,
            countryCode: '+55',
            areaCode: '11',
            bilingual: selectedLanguages.length > 1
        }

        const dataAuth0 = {
            email: form.getFieldValue('email'),
            user_metadata: {},
            blocked: false,
            email_verified: false,
            app_metadata: {},
            given_name: form.getFieldValue('firstName'),
            family_name: form.getFieldValue('lastName'),
            name: form.getFieldValue('firstName'),
            nickname: `${form.getFieldValue('firstName')}123`,
            picture: form.getFieldValue('photo'),
            user_id: '',
            connection: 'Username-Password-Authentication',
            password: 'P3rs0n4l123',
        }

        dispatch(createPersonalAuth0(dataAuth0)).then(res => {
            const authId = res.payload.user_id
            const users = [res.payload.user_id]
            dispatch(postAuthRole(users))
            if (res.meta.requestStatus === 'fulfilled') {
                dispatch(createPersonal({ ...data, authId })).then(res => {
                    if (res.meta.requestStatus === 'fulfilled') {
                        alert.success('Profissional criado com sucesso')
                        navigate(`/profissionais/${res.payload.id}`)
                    } else {
                        alert.error('Ops, houve um erro ao criar o profissional.')
                    }
                }).catch(err => {
                    alert.error('Ops, houve um erro ao criar o profissional.');
                })
            } else {
                alert.error('Houve um erro ao criar o profissional.')
            }
        })


    }

    const handleSelectGym = (gym) => {
        const copy = [...selectedGyms]
        const index = copy.indexOf(gym)
        if (index === -1) {
            copy.push(gym)
        } else {
            console.log(index)
            copy.splice(index, 1)
        }
        setSelectedGyms([...copy])
        setGymError(false)
    }

    const handleSelectLanguage = (lang) => {
        const copy = [...selectedLanguages]
        const index = copy.indexOf(lang)
        if (index === -1) {
            copy.push(lang)
        } else {
            copy.splice(index, 1)
        }
        setSelectedLanguages([...copy])
        setLangError(false)
    }

    const handleSelectSpecialty = (specialty) => {
        const copy = [...selectedSpecialties]
        const index = copy.indexOf(specialty)
        if (index === -1) {
            copy.push(specialty)
        } else {
            copy.splice(index, 1)
        }
        setSelectedSpecialties([...copy])
        setSpecialtyError(false)
    }

    const handleNextStep = async () => {
        const nextStep = step + 1
        let hasError = false
        if (nextStep === 2) {
            const fields = ['firstName', 'lastName', 'cref', 'cpf', 'birthDate', 'gender', 'email', 'phoneNumber', 'personalZoopId']
            const isValid = await form.validateFields(fields)
            if (!isValid.errorFields) setStep(nextStep)
        }
        if (nextStep === 3 && selectedLanguages.length === 0) {
            setLangError(true)
            hasError = true
        }
        if (nextStep === 3 && selectedSpecialties.length === 0) {
            setSpecialtyError(true)
            hasError = true
        }
        // if(nextStep === 4 && selectedGyms.length === 0) {
        //     setGymError(true)
        //     hasError = true
        // }

        if (!hasError) {
            if (nextStep !== 4) {
                setStep(nextStep)
            } else {
                handleCreatePersonal()
            }
        }
    }

    const handlePrevStep = () => {
        setStep(step - 1)
    }

    useEffect(() => {
        dispatch(getSpecialties())
        dispatch(getLanguages())
        dispatch(getGyms())
    }, [])


    return (
        <Layout>
            <Row gutter={[20, 20]}>
                <Col md={24}>
                    <h2 className="title tx-center">Cadastrar novo parceiro</h2>
                </Col>
                <Col md={24} className="mt-20">
                    <Form form={form}>
                        <Row>
                            {step === 1 && (
                                <Step1
                                    form={form}
                                />
                            )}
                            {step === 2 && (
                                <Step2
                                    languages={languages}
                                    specialties={specialties}
                                    handleSelectLanguage={handleSelectLanguage}
                                    handleSelectSpecialty={handleSelectSpecialty}
                                    langError={langError}
                                    specialtyError={specialtyError}
                                    selectedLanguages={selectedLanguages}
                                    selectedSpecialties={selectedSpecialties}
                                />
                            )}
                            {step === 3 && (
                                <Step3
                                    gyms={gyms}
                                    selectedGyms={selectedGyms}
                                    gymError={gymError}
                                    handleSelectGym={handleSelectGym}
                                />
                            )}
                            <Col md={24} className="d-flex justify-center mt-50">
                                {step > 1 && <Button type="secondary" onClick={handlePrevStep}>Voltar</Button>}
                                <Button loading={isSubmit} type="primary" onClick={handleNextStep} className={`${step > 1 && 'ml-20'}`}>{step < 3 ? 'Continuar' : 'Salvar'}</Button>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </Layout>
    )
}

export default CreateProfessional