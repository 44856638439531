import { Form, Input, Upload, Button } from 'antd'
import { PlusCircleOutlined } from '@ant-design/icons'
import { LoadingOutlined } from '@ant-design/icons'
import { uploadObject } from '../../utils/bucket'

const SPACES_URL = 'https://dev-personal-space.nyc3.digitaloceanspaces.com/specialty-image/'

const FormSpecialty = ({ onSubmit, closeModal, isSubmit, form, editMode, onUpdate }) => {

    const handleUpload = async ({ file, onSuccess, onError }) => {
        if(!file) {
            alert('Please, insert a file')
            return
        }
        const current = new Date().getTime()
        const name = current+file.name
        const uploadResponse = await uploadObject(name, file, 'specialty')

        if(uploadResponse) {
            onSuccess()
            form.setFieldValue('picture', SPACES_URL+name)
        } else {
            onError()
        }
    }

    return (
        <Form form={form} onFinish={editMode ? onUpdate : onSubmit}>
            <Form.Item 
                name='name'
                rules={[{ required: true, message: 'Este campo é obrigatório' }]}
            >
                <Input type='text' placeholder='Nome' />
            </Form.Item>
            <Form.Item 
                name='price'
                rules={[{ required: true, message: 'Este campo é obrigatório' }]}
            >
                <Input type='text' placeholder='Valor' className='mt-10' />
            </Form.Item>
            <Form.Item 
                name='picture'
                rules={[{ required: true, message: 'Este campo é obrigatório' }]}
            >
                <Input type='text' placeholder='URL da imagem' className='mt-10' readOnly />
            </Form.Item>
            <Upload className='mt-20 w-100' listType="picture-card"
                customRequest={handleUpload}
            >
                <div className='flex-center-flow w-100'>
                    <PlusCircleOutlined className='tx-gray' style={{fontSize: 28}} />
                    <h3 className="tx-gray">Adicionar<br />imagem</h3>
                </div>
            </Upload>
            <Form.Item>
                <div className='flex-center'>
                    <Button onClick={() => closeModal(false)} type="default" disabled={isSubmit}>Cancelar</Button>
                    <Button 
                        type='primary' 
                        htmlType='submit' 
                        className='ml-20' 
                        disabled={isSubmit}
                    >
                        {isSubmit ? <LoadingOutlined /> : 'Salvar' }
                    </Button>
                </div>
            </Form.Item>            
        </Form>
    )
}

export default FormSpecialty