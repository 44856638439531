import React from 'react'
import './sidebar.less'
import { Layout } from 'antd'
import { Link, useLocation } from 'react-router-dom'
import { LogoutOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom"
import { destroyInfoLocalStorage } from "../../redux/api/config"

const { Sider } = Layout

const menu = [
    {
        title: 'Dashboard',
        key: 'dashboard',
        link: '/'
    }, {
        title: 'Profissionais',
        key: 'profissionais',
        link: '/profissionais'
    }, {
        title: 'Especialidades',
        key: 'especialidades',
        link: '/especialidades'
    }, {
        title: 'Academias',
        key: 'academias',
        link: '/academias'
    }
]

const SidebarComponent = () => {

    const location = useLocation()
    const path = location.pathname
    const navigate = useNavigate()

    const logout = () => {
        destroyInfoLocalStorage()
        navigate('/')
    }

    return (
        <Sider className="p-2" width="10vw"
        style={{
            overflow: 'auto',
            height: '100vh',
            position: 'fixed',
            left: 0,
            top: 0,
            bottom: 0,
          }}
        >
            <div className="sider-wrapper">
                <div className="logo"></div>
                <div className="sider-menu">
                    {menu.map((i, p) => (
                        <Link className={i.link === path && "item-active"} to={i.link}>{i.title}</Link>
                    ))}
                </div>
                <div className="sider-footer">
                    <LogoutOutlined onClick={logout} />
                </div>
            </div>
        </Sider>
    )
}

export default SidebarComponent