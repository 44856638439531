import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { request } from "../api/config"

const initialState = {
    isLoading: false,
    success: false,
    message: null,
    specialties: [],
    isSubmit: false
}

export const getSpecialties = createAsyncThunk(
    'specialties/',
    async (values, thunkAPI) => {
        try {
            const res = await request('/specialties')
            if(res.code === 200) {
                return res.value
            } else {
                return thunkAPI.rejectWithValue('Houve um erro ao retornar as especialidades.')
            }
        } catch (e) {
            return thunkAPI.rejectWithValue('Houve um erro ao retornar as especialidades.')
        }
    }
)

export const createSpecialty = createAsyncThunk(
    'specialties/create',
    async (values, thunkAPI) => {
        try {
            const res = await request('/specialties', 'POST', values)
            if(res.code === 200) {
                return res.value
            } else {
                return thunkAPI.rejectWithValue('Houve um erro ao criar a especialidade.')
            }
        } catch (e) {
            return thunkAPI.rejectWithValue('Houve um erro ao criar a especialidade.')
        }
    }
)

export const updateSpecialty = createAsyncThunk(
    'specialties/update',
    async (values, selectedId, thunkAPI) => {
        console.log(values)
        try {
            const res = await request(`/specialties/${values.id}`, "PUT", values)
            if(res.code === 200) {
                return res.value
            } else {
                return thunkAPI.rejectWithValue('Houve um erro ao atualizar a especialidade.')
            }
        } catch (e) {
            return thunkAPI.rejectWithValue('Houve um erro ao atualizar a especialidade.')
        }
    }
)

const specialtiesSlice = createSlice({
    name: 'specialties',
    initialState,
    extraReducers(builder) {
        builder
            .addCase(getSpecialties.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getSpecialties.fulfilled, (state, { payload }) => {
                state.isLoading = false
                state.success = true
                state.specialties = payload
            })
            .addCase(getSpecialties.rejected, (state, { payload }) => {
                state.isLoading = false
                state.success = false
                state.message = payload
            })
            .addCase(createSpecialty.pending, (state) => {
                state.isSubmit = true
            })
            .addCase(createSpecialty.fulfilled, (state) => {
                state.isSubmit = false
            })
            .addCase(createSpecialty.rejected, (state, { payload }) => {
                state.isSubmit = false
            })
    }
})

const { reducer } = specialtiesSlice

export default reducer

