import { PutObjectCommand, S3Client } from '@aws-sdk/client-s3';


const params = {
    Bucket: "dev-personal-space", // The path to the directory you want to upload the object to, starting with your Space name.
    ACL: "public-read", // Defines ACL permissions, such as private or public.
};

export const uploadObject = async (key, body, type) => {
    const s3Client = new S3Client({
      endpoint: type === 'specialty' ? "https://nyc3.digitaloceanspaces.com/specialty-image" : "https://nyc3.digitaloceanspaces.com/personal-image",
      forcePathStyle: false, // Configures to use subdomain/virtual calling format.
      region: "nyc3", // Must be "us-east-1" when creating new Spaces. Otherwise, use the region in your endpoint (e.g. nyc3).
      credentials: {
        accessKeyId: "DO00Z338LYYCQQKLB7J3", // Access key pair. You can create access key pairs using the control panel or API.
        secretAccessKey: "Av5w27cmZCKJZyyd4qXKw6G4J33ge4wSNIIm1brOMx0" // Secret access key defined through an environment variable.
      },
      sslEnabled: false,
  });

    params.Key = key
    params.Body = body
    try {
      const data = await s3Client.send(new PutObjectCommand(params));
      console.log(data)
      return data;
    } catch (err) {
      console.log("Error", err);
    }
  };