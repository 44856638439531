import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom"
import { Professionals, Gyms, Specialties, Login, CreateProfessional, Professional } from "../pages"
import { store } from '../redux/store'
import { Provider } from 'react-redux'
import { getInfoLocalStorage } from "../redux/api/config"
import moment from "moment"

const ProtectedRoute = ({ children }) => {
    const stateFromLocalStorage = getInfoLocalStorage() 
    const token = stateFromLocalStorage.token
    const expireDate = stateFromLocalStorage.expire_date
    if(!token || moment().isAfter(expireDate)) {
        return <Navigate to="/" replace />
    }

    return children
}

const RouterWrapper = () => {
    return (
        <Router>
            <Provider store={store}>
                <Routes>
                    <Route exact sensitive path="/" element={<Login />} />
                        <Route exact path="/dashboard" element={<ProtectedRoute><Professionals /></ProtectedRoute>} />
                        <Route exact path="/profissionais" element={<ProtectedRoute><Professionals /></ProtectedRoute>} />
                        <Route exact path="/profissionais/novo" element={<ProtectedRoute><CreateProfessional /></ProtectedRoute>} />
                        <Route exact path="/academias" element={<ProtectedRoute><Gyms /></ProtectedRoute>} />
                        <Route exact path="/especialidades" element={<ProtectedRoute><Specialties /></ProtectedRoute>} />
                        <Route exact path="/profissionais/:id" element={<ProtectedRoute><Professional /></ProtectedRoute>} />
                </Routes>
            </Provider>
        </Router>
    )
}

export default RouterWrapper