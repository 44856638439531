import { Layout, Form, Input, Button, Alert } from "antd"
import logo from '../../assets/img/logo.svg'
import { useDispatch, useSelector } from "react-redux"
import { LoadingOutlined } from '@ant-design/icons'
import { onLogin } from "../../redux/slices/loginSlice"
import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { getInfoLocalStorage } from "../../redux/api/config"
import moment from 'moment'

const Login = () => {
    const dispatch = useDispatch()
    const [form] = Form.useForm()
    const { isLoading, success, message } = useSelector(state => state.login)
    const navigate = useNavigate()
    const stateFromLocalStorage = getInfoLocalStorage()

    const onFinishFailed = ({ errorFields }) => {
        form.scrollToField(errorFields[0].name)
    }

    const onFinish = (values) => {
        dispatch(onLogin(values))
        .then(() => {
            navigate('/dashboard')
        })
    }

    useEffect(() => {
        if(stateFromLocalStorage.token && moment().isBefore(stateFromLocalStorage.expire_date)) {
            navigate('/dashboard')
        }
    }, [])

    return (
        <Layout className="h-100 flex-center">
            <div className="loginWrapper flex-center-flow">
                <img src={logo} />
                {!success && message !== null ? <Alert type="error" message={message} /> : ''}
                <Form layout="vertical" onFinish={onFinish} onFinishFailed={onFinishFailed} form={form}>
                    <Form.Item
                        label="E-mail"
                        name="email"
                        rules={[
                            {
                                required: true,
                                message: 'Este campo é obrigatório'
                            }
                        ]}
                    >
                        <Input type="email" />
                    </Form.Item>
                    <Form.Item
                        label="Senha"
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: 'Este campo é obrigatório'
                            }
                        ]}
                    >
                        <Input type="password" />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">{isLoading ? <LoadingOutlined /> : 'Enviar'}</Button>
                    </Form.Item>
                </Form>
            </div>
        </Layout>
    )
}

export default Login