import { Table } from 'antd'

const TableComponent = (props) => {
    const { columns, data, isLoading } = props

    return (
        <Table
            columns={columns}
            dataSource={data}
            size={'medium'}
            loading={isLoading}
        />
    )
}

export default TableComponent