import React, { useState } from 'react';
import { Row, Col, Form, Input, Button } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import axios from "axios";

const FormGym = ({ onSubmit, isSubmit, closeModal, form }) => {
    const [street, setStreet] = useState('');
    const [number, setNumber] = useState('');

    const handleCep = (cep) => {
        // console.log('info -->', cep?.length, cep?.length === 8, street, number)
        if (cep?.length === 8 && street && number) {
            const address = `${street}, ${number}, ${cep}`;
            axios.request({
                method: 'get',
                url: `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=AIzaSyDKNkVEtpRZYVJvL-IHKgq-3zckXdBKDwk`
            })
                .then(res => {
                    if (res.data.status === "OK") {
                        const length = res.data.results[0].address_components.length;
                        form.setFieldsValue({
                            latitude: res.data.results[0].geometry.location.lat,
                            longitude: res.data.results[0].geometry.location.lng,
                            country: res.data.results[0].address_components[length - 1].long_name,
                            state: res.data.results[0].address_components[length - 2].long_name,
                            city: res.data.results[0].address_components[length - 3].long_name,
                            neighborhood: res.data.results[0].address_components[length - 4].long_name,
                            street: res.data.results[0].formatted_address,
                        });
                    }
                })
                .catch(e => {
                    console.log(e);
                });
        }
    };

    return (
        <Form form={form} onFinish={onSubmit}>
            <Row gutter={15}>
                <Col md={12}>
                    <Form.Item
                        name="name"
                        rules={[{ required: true, message: 'Este campo é obrigatório' }]}
                    >
                        <Input type="text" placeholder="Nome" />
                    </Form.Item>
                </Col>
                <Col md={12}>
                    <Form.Item
                        name="postalCode"
                        rules={[
                            { required: true, message: 'Este campo é obrigatório' },
                            { len: 8, message: 'O Código Postal deve ter 8 caracteres' }
                        ]}
                    >
                        <Input type="text" placeholder="Código Postal" onBlur={(e) => handleCep(e.target.value)} />
                    </Form.Item>
                </Col>
                <Col md={12}>
                    <Form.Item
                        name="streetNumber"
                        rules={[{ required: true, message: 'Este campo é obrigatório' }]}
                    >
                        <Input
                            type="number"
                            placeholder="Número"
                            onChange={(e) => {
                                setNumber(e.target.value);

                            }}
                            onBlur={(e) => {
                                setNumber(e.target.value);
                                handleCep(form.getFieldValue('postalCode'));
                            }}
                        />
                    </Form.Item>
                </Col>
                <Col md={24}>
                    <Form.Item
                        name="street"
                        rules={[{ required: true, message: 'Este campo é obrigatório' }]}
                    >
                        <Input
                            type="text"
                            placeholder="Logradouro"
                            onChange={(e) => {
                                setStreet(e.target.value);

                            }}
                            onBlur={(e) => {
                                setNumber(e.target.value);
                                handleCep(form.getFieldValue('postalCode'));
                            }}
                        />
                    </Form.Item>
                </Col>
                <Col md={12}>
                    <Form.Item
                        name="latitude"
                        rules={[{ required: true, message: 'Este campo é obrigatório' }]}
                    >
                        <Input type="text" placeholder="Latitude" readOnly />
                    </Form.Item>
                </Col>
                <Col md={12}>
                    <Form.Item
                        name="longitude"
                        rules={[{ required: true, message: 'Este campo é obrigatório' }]}
                    >
                        <Input type="text" placeholder="Longitude" readOnly />
                    </Form.Item>
                </Col>
                <Col md={12}>
                    <Form.Item
                        name="country"
                        rules={[{ required: true, message: 'Este campo é obrigatório' }]}
                    >
                        <Input type="text" placeholder="País" />
                    </Form.Item>
                </Col>
                <Col md={12}>
                    <Form.Item
                        name="state"
                        rules={[{ required: true, message: 'Este campo é obrigatório' }]}
                    >
                        <Input type="text" placeholder="Estado" />
                    </Form.Item>
                </Col>
                <Col md={12}>
                    <Form.Item
                        name="city"
                        rules={[{ required: true, message: 'Este campo é obrigatório' }]}
                    >
                        <Input type="text" placeholder="Cidade" />
                    </Form.Item>
                </Col>
                <Col md={12}>
                    <Form.Item
                        name="neighborhood"
                        rules={[{ required: true, message: 'Este campo é obrigatório' }]}
                    >
                        <Input type="text" placeholder="Bairro" />
                    </Form.Item>
                </Col>
                <Col md={12}>
                    <Form.Item
                        name="complement"
                    >
                        <Input type="text" placeholder="Complemento" />
                    </Form.Item>
                    <Form.Item name="id">
                        <Input type="number" hidden />
                    </Form.Item>
                </Col>
                <Col md={24}>
                    <Form.Item>
                        <div className='flex-center'>
                            <Button onClick={closeModal} type="default" disabled={isSubmit}>Cancelar</Button>
                            <Button
                                type='primary'
                                htmlType='submit'
                                className='ml-20'
                                disabled={isSubmit}
                            >
                                {isSubmit ? <LoadingOutlined /> : 'Salvar'}
                            </Button>
                        </div>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};

export default FormGym;
