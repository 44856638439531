import { Col, Checkbox, Row, Card } from "antd"

const Step2 = ({ languages, specialties, handleSelectLanguage, handleSelectSpecialty, langError, specialtyError, selectedLanguages, selectedSpecialties }) => {
    return (
        <Col md={24}>
            <div className="form-wrapper">
                <div className="d-flex w-100">
                    <h4>Idiomas</h4>
                    <hr />
                </div>
                <div className="d-flex">
                    {languages.map((lang, i) => (
                        <Checkbox checked={selectedLanguages.indexOf(lang) !== -1} key={`lang-${i}`} onChange={() => handleSelectLanguage(lang)}>{lang.name}</Checkbox>
                    ))}
                </div>
                {langError && <span className="tx-red">Por favor, selecione um ou mais idioma(s).</span>}
                <div className="d-flex mt-50">
                    <h4>Especialidades</h4>
                    <hr />
                </div>
                <Row gutter={[15, 15]}>
                    {specialties.map((specialty, i) => (
                        <Col md={8} key={`specialty-${i}`}>
                            <Card onClick={() => handleSelectSpecialty(specialty)}>
                                <div className="d-flex">
                                    <Checkbox checked={selectedSpecialties.indexOf(specialty) !== -1} onChange={() => handleSelectSpecialty(specialty)}>
                                        <img src={specialty.picture} alt={`Imagem de ${specialty.name}`} className="img-specialty" />
                                        <span>{specialty.name}</span>
                                    </Checkbox>
                                </div>
                            </Card>
                        </Col>
                    ))}
                    {specialtyError && <span className="tx-red">Por favor, selecione uma ou mais especialidade(s).</span>}
                </Row>
            </div>
        </Col>
    )
}

export default Step2