import { Col, Form, Row, Input, Select, Upload } from "antd"
import { uploadObject } from '../../utils/bucket'
import { PlusCircleOutlined } from '@ant-design/icons'

const SPACES_URL = 'https://dev-personal-space.nyc3.digitaloceanspaces.com/personal-image/'

const { Option } = Select

const Step1 = ({ form }) => {
    const sanitizeFileName = (fileName) => {
        const sanitized = fileName.replace(/[^a-zA-Z0-9\s]/g, '-');

        const trimmed = sanitized.replace(/\s/g, '');

        return trimmed;
    };


    const handleUpload = async ({ file, onSuccess, onError }) => {
        if (!file) {
            alert('Please, insert a file')
            return
        }
        const current = new Date().getTime()
        const name = current + sanitizeFileName(file.name)

        const uploadResponse = await uploadObject(name, file, 'personal')

        if (uploadResponse) {
            onSuccess()
            form.setFieldValue('photo', SPACES_URL + name)
        } else {
            onError()
        }
    }

    const formatInputDate = (event) => {
        const inputDate = event.target.value.replace(/\D/g, ''); // Remove all non-digit characters
        // Apply date mask
        let formattedDate = '';
        if (inputDate.length >= 1) {
            formattedDate += inputDate.substring(0, 2);
        }
        if (inputDate.length >= 3) {
            formattedDate += '/' + inputDate.substring(2, 4);
        }
        if (inputDate.length >= 5) {
            formattedDate += '/' + inputDate.substring(4, 8);
        }

        form.setFieldValue('birthDate', formattedDate)
    }

    return (
        <>
            <Col md={12}>
                <div className="form-wrapper">
                    <Row gutter={15}>
                        <Col md={12}>
                            <Form.Item
                                name='firstName'
                                rules={[{ required: true, message: 'Este campo é obrigatório' }]}
                            >
                                <Input type='text' placeholder='Nome' />
                            </Form.Item>
                        </Col>
                        <Col md={12}>
                            <Form.Item
                                name='lastName'
                                rules={[{ required: true, message: 'Este campo é obrigatório' }]}
                            >
                                <Input type='text' placeholder='Sobrenome' />
                            </Form.Item>
                        </Col>
                        <Col md={12}>
                            <Form.Item
                                name='cref'
                                rules={[{ required: true, message: 'Este campo é obrigatório' }]}
                            >
                                <Input type='text' placeholder='CREF' />
                            </Form.Item>
                        </Col>
                        <Col md={12}>
                            <Form.Item
                                name='cpf'
                                rules={[{ required: true, message: 'Este campo é obrigatório' }]}
                            >
                                <Input type='text' placeholder='CPF' />
                            </Form.Item>
                        </Col>
                        <Col md={12}>
                            <Form.Item
                                name='birthDate'
                                rules={[{ required: true, message: 'Este campo é obrigatório' }]}
                            >
                                <Input type='text' placeholder='Data de nascimento' onChange={formatInputDate} />
                            </Form.Item>
                        </Col>
                        <Col md={12}>
                            <Form.Item
                                name='gender'
                                rules={[{ required: true, message: 'Este campo é obrigatório' }]}
                                initialValue="MALE"
                            >
                                <Select>
                                    <Option value="MALE">Masculino</Option>
                                    <Option value="FEMALE">Feminino</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col md={12}>
                            <Form.Item
                                name='email'
                                rules={[{ required: true, message: 'Este campo é obrigatório' }]}
                            >
                                <Input type='text' placeholder='E-mail' />
                            </Form.Item>
                        </Col>
                        <Col md={12}>
                            <Form.Item
                                name='phoneNumber'
                                rules={[{ required: true, message: 'Este campo é obrigatório' }]}
                            >
                                <Input type='text' placeholder='Telefone' />
                            </Form.Item>
                        </Col>
                        <Col md={24}>
                            <Form.Item
                                name='personalZoopId'
                                rules={[{ required: true, message: 'Este campo é obrigatório' }]}
                            >
                                <Input type='text' placeholder='Zopp ID' />
                            </Form.Item>
                        </Col>
                    </Row>
                </div>
            </Col>
            <Col md={12} style={{ paddingLeft: '16px' }}>
                <Upload className='mt-20 w-100' listType="picture-card"
                    customRequest={handleUpload}
                >
                    <div className='flex-center-flow w-100'>
                        <PlusCircleOutlined className='tx-gray' style={{ fontSize: 28 }} />
                        <h3 className="tx-gray">Adicionar<br />imagem</h3>
                    </div>
                </Upload>
            </Col>
        </>
    )
}

export default Step1