import React from 'react'
import './layout.less'
import { Layout } from 'antd'
import { SidebarComponent as Sidebar} from "../../components"

const { Content } = Layout

const LayoutComponent = (props) => {
    const { children } = props

    return (
        <Layout className="h-100">
            <Sidebar />
            <Layout>
                <Content className="content-p">
                    {children}
                </Content>
            </Layout>
        </Layout>
    )
}

export default LayoutComponent