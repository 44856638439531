import { configureStore } from "@reduxjs/toolkit";
import loginReducer from "./slices/loginSlice"
import gymsReducer from "./slices/gymsSlice"
import specialtiesReducer from "./slices/specialtiesSlice"
import personalsReducer from "./slices/personalsSlice"
import languagesReducer from "./slices/languagesSlice"

export const store = configureStore({
    reducer: {
        login: loginReducer,
        gyms: gymsReducer,
        specialties: specialtiesReducer,
        personals: personalsReducer,
        languages: languagesReducer
    }
})