import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setInfoLocalStorage } from "../api/config"
import axios from 'axios'
import qs from 'qs'
import moment from 'moment'

const initialState = {
    isLoading: false,
    success: false,
    message: null,
}

const errors = {
    403: 'E-mail ou senha incorretos',
    500: 'Houve um erro ao realizar o login'
}

export const onLogin = createAsyncThunk(
    'login/',
    async (values, thunkAPI) => {
        try {
            var data = qs.stringify({
                'grant_type': 'password',
                'username': values.email,
                'password': values.password,
                'audience': 'https://backend.personal',
                'scope': '',
                'client_id': 'UV1IjvZwV9ER2T5rlN5CyAdd3FeR2ycS',
                'client_secret': 'jLrWC3NUjUqnOoVvMjFfLCx3EHzeTzIZEcm9bDNF3N3hy_3iIwir7_34AtQ_gPR2' 
              });
              var config = {
                method: 'post',
                url: 'https://yellow-tooth-5932.us.auth0.com/oauth/token/',
                headers: { 
                  'Content-Type': 'application/x-www-form-urlencoded', 
                },
                data : data,
              };
              
              const response = await axios(config)
              const expire_date = moment().add(1, 'days')
              const userInfo = { token: response.data.access_token, expire_date }
              setInfoLocalStorage(userInfo)
              return true
              
        } catch (e) {
            return thunkAPI.rejectWithValue(errors[e.response.status])
        }
    }
)

const loginSlice = createSlice({
    name: 'login',
    initialState,
    extraReducers(builder) {
        builder
            .addCase(onLogin.pending, (state) => {
                state.isLoading = true
            })
            .addCase(onLogin.fulfilled, (state) => {
                state.isLoading = false
                state.success = true
            })
            .addCase(onLogin.rejected, (state, { payload }) => {
                state.isLoading = false
                state.success = false
                state.message = payload
            })
    }
})

const { reducer } = loginSlice

export default reducer

