import { LayoutComponent as Layout } from "../../components"
import { Row, Col, Button, Modal, message as alert, Form } from 'antd'
import TableGyms from "./tableGyms"
import { getGyms, deleteGym, createGym, updateGym } from "../../redux/slices/gymsSlice"
import { useDispatch, useSelector } from "react-redux"
import { useEffect, useRef, useState } from "react"
import FormGym from "./formGym"
import { LoadingOutlined } from '@ant-design/icons'


const Gyms = () => {

    const { isLoading, gyms, isSubmit } = useSelector(state => state.gyms)
    const dispatch = useDispatch()
    const [form] = Form.useForm()
    const [openModal, setOpenModal] = useState(false)
    const [gymIndex, setGymIndex] = useState(-1)
    const edit = useRef(false)
    const [openFormModal, setOpenFormModal] = useState(false)

    const handleFormModal = () => {
        setOpenFormModal(false)
        form.resetFields()
        setGymIndex(-1)
    }

    const handleOpenModal = (index) => {
        setGymIndex(index)
        setOpenModal(!openModal)
    }

    const handleEditModal = (gym) => {
        form.setFieldsValue(gym)
        setOpenFormModal(true)
        edit.current = !edit.current
    }

    const handleDelete = () => {
        const data = { id: gyms[gymIndex].id }
        dispatch(deleteGym(data)).then(res => {
            handleOpenModal(-1)
            dispatch(getGyms())
            alert.success('Academia deletada com sucesso.')
        }).catch(err => {
            alert.error('Ops, houve um erro ao deletar a academia.');
        })
    }

    const handleCreate = (values) => {
        dispatch(createGym(values)).then(res => {
            if (res.error) {
                return alert.error(res.payload)
            }
            handleFormModal()
            dispatch(getGyms())
            alert.success('Academia cadastrada com sucesso.')
        }).catch(err => {
            alert.error('Ops, houve um erro ao criar a academia.');
        })
    }

    const handleUpdate = (values) => {
        dispatch(updateGym(values)).then(res => {
            if (res.error) {
                return alert.error(res.payload)
            }
            handleFormModal()
            dispatch(getGyms())
            alert.success('Academia atualizada com sucesso.')
        }).catch(err => {
            alert.error('Ops, houve um erro ao atualizar a academia.');
        })
    }

    const handleSubmit = (values) => {
        if (edit.current) {
            handleUpdate(values)
        } else {
            handleCreate(values)
        }
    }

    useEffect(() => {
        dispatch(getGyms())
    }, [])

    return (
        <Layout>
            <Row gutter={[20, 20]}>
                <Col md={12}>
                    <h2>Academias Cadastradas</h2>
                </Col>
                <Col md={12} className="justify-end">
                    <Button type="primary" size="large" onClick={() => setOpenFormModal(true)}>Cadastrar academia</Button>
                </Col>
                <Col md={24}>
                    <TableGyms isLoading={isLoading} data={gyms} handleOpenModal={handleOpenModal} handleEditModal={handleEditModal} />
                </Col>
            </Row>
            <Modal
                open={openModal}
                title="Deletar academia"
                onCancel={() => handleOpenModal(-1)}
                footer={''}
                maskClosable={false}
            >
                {gymIndex !== -1 && (
                    <>
                        <h3>Deseja deletar a academia {gyms[gymIndex].name}?</h3>
                        <div className="d-flex mt-20">
                            <Button type="danger" disabled={isSubmit} onClick={handleDelete}> {isSubmit ? <LoadingOutlined /> : 'Sim, deletar'}</Button>
                            <Button type="secondary" disabled={isSubmit} className="ml-20" onClick={() => handleOpenModal(-1)}>Não, cancelar.</Button>
                        </div>
                    </>
                )}
            </Modal>
            <Modal
                open={openFormModal}
                title="Cadastrar academia"
                maskClosable={false}
                onCancel={handleFormModal}
                footer={''}
            >

                <FormGym
                    onSubmit={handleSubmit}
                    isSubmit={isSubmit}
                    closeModal={handleFormModal}
                    form={form}
                    handleEditModal={handleEditModal}
                />
            </Modal>
        </Layout>
    )
}

export default Gyms