import axios from "axios";

export const LOCAL_STORAGE_KEY = '2a95b9ff782470d9c58102afea775f8f5136fb1a'

export const API_URL = process.env.REACT_APP_API_URL

export const DOCS_URL = process.env.REACT_APP_DOCS_URL

export const request = async (url, method = 'GET', body) => {
    const { token, authToken } = getInfoLocalStorage();
    const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    }
    if (token) {
        headers.Authorization = `Bearer ${url.includes('yellow') ? authToken : token}`
    }
    const config = {
        maxBodyLength: Infinity,
        method,
        url: url.includes('yellow') ? url : API_URL + url,
        headers,
        data: body === undefined ? undefined : JSON.stringify(body)
    }

    const response = await axios(config)
    const result = response.data
    return result
}

export const getInfoLocalStorage = () => (
    JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY)) || {}
)

export const setInfoLocalStorage = (data) => {
    const infoFromLocalStorage = getInfoLocalStorage()
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify({
        ...infoFromLocalStorage,
        ...data
    }))
}

export const destroyInfoLocalStorage = () => (
    localStorage.removeItem(LOCAL_STORAGE_KEY)
)